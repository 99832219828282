import React from 'react';
import {Box, Typography, Card, CardContent, CardMedia, CardActionArea, useMediaQuery, useTheme} from '@mui/material';

function SectionThree() {
  const blogPosts = [
    {
      title: 'Jak myć panele fotowoltaiczne?',
      content: 'Kilka wskazówek dotyczących mycia...',
      // adres zdjęcia z posta na blogu
      image: 'https://panelenablysk.pl/userdata/public/news/images/8.jpg',
      // link do bloga
      url: 'https://panelenablysk.pl/jak-myc-panele'
    },
    {
      title: 'Opłacalność instalacji fotowoltaicznej w 2024 roku',
      content: 'Ceny energii ciągle rosną...',
      image: 'https://panelenablysk.pl/userdata/public/news/images/7.jpg',
      url: 'https://panelenablysk.pl/pl/n/7'
    },
    {
      title: 'Fakty i mity dotyczące mycia paneli słonecznych',
      content: 'Zdziwisz się...',
      image: 'https://panelenablysk.pl/userdata/public/news/images/6.jpg',
      url: 'https://panelenablysk.pl/pl/n/6'
    }
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box px={2}>
      <Typography variant="h5" gutterBottom>Dowiedz się więcej:</Typography>
      <Box display="flex" justifyContent="space-between" mt={4}>
        {(isMobile ? blogPosts.slice(0, 2) : blogPosts).map((post, index) => (
          <Card key={index} style={{ flex: 1, margin: '0 8px', backgroundColor: '#fccc44', color: '#000'}}>
            <CardActionArea href={post.url} style={{height: '100%'}} target="_blank" rel="noopener noreferrer">
              <CardMedia
                component="img"
                height="140"
                image={post.image}
                alt={post.title}
              />
              <CardContent>
                <Typography variant="h7" style={{ fontWeight: 'bold' }}>{post.title}</Typography>
                <Typography variant="body2" py={2}>{post.content}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

export default SectionThree;
