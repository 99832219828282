import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import ContactForm from "./ContactForm";

function SectionTwo() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [price, setPrice] = useState(null);
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      power: '',
      location: ''
    }
  });

  const onSubmit = (data) => {
    const basePrice = parseFloat(data.power) * 50;
    const roofFee = data.location === 'roof' ? 200 : 0;
    const totalPrice = basePrice + roofFee;
    const finalPrice = totalPrice < 500 ? 500 : totalPrice;

    setPrice(finalPrice);
    setShowContactForm(true);
  };

  return (
    <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', md: 'row' }}>
      <Box flex={1} p={2} pb={4}>
        <Typography variant="h5" gutterBottom mb={3}>Nasza Profesjonalna Usługa Mycia Paneli</Typography>

        <Typography variant="body1" gutterBottom>
          🌟 <strong>Doświadczenie i Profesjonalizm</strong><br />
          <Typography variant="body2">
            Od lat specjalizujemy się w czyszczeniu paneli fotowoltaicznych, zapewniając najwyższą jakość usług.
          </Typography>
        </Typography>

        <Typography variant="body1" gutterBottom>
          🧪 <strong>Bezpieczne i Skuteczne Metody</strong><br />
          <Typography variant="body2">
            Używamy specjalistycznych środków czystości i sprzętu, które są bezpieczne dla paneli i środowiska.
          </Typography>
        </Typography>

        <Typography variant="body1" gutterBottom>
          🚀 <strong>Szybkość i Elastyczność</strong><br />
          <Typography variant="body2">
            Oferujemy dogodne terminy realizacji i szybkie wykonanie usługi, dostosowane do Twoich potrzeb.
          </Typography>
        </Typography>

        <Typography variant="body1" gutterBottom>
          🌍 <strong>Obsługujemy Twój Region</strong><br />
          <Typography variant="body2">
            Świadczymy usługi na terenie całego kraju, dzięki czemu zawsze jesteśmy blisko Ciebie.
          </Typography>
        </Typography>
      </Box>

      <Box flex={1} p={2}>
        {showContactForm ? (
          <>
            <Typography variant="h6">
              Szacowana cena usługi: <span style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>{price} zł</span>
            </Typography>
            <ContactForm price={price} power={control._formValues.power} location={control._formValues.location} />
          </>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>Kalkulator wyceny:</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" alignItems="center">
                <Controller
                  name="power"
                  control={control}
                  rules={{
                    required: "Moc instalacji jest wymagana",
                    pattern: {
                      value: /^[1-9]\d*$/,
                      message: "Wprowadź dodatnią liczbę całkowitą"
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Moc instalacji (kWp)"
                      variant="outlined"
                      margin="normal"
                      error={!!errors.power}
                      helperText={errors.power ? errors.power.message : ''}
                      style={{ marginRight: '16px', maxWidth: '50%' }}
                    />
                  )}
                />

                <Controller
                  name="location"
                  control={control}
                  rules={{ required: "Wybierz położenie paneli" }}
                  render={({ field }) => (
                    <FormControl component="fieldset" error={!!errors.location}>
                      <RadioGroup row {...field} >
                        <FormControlLabel value="roof" control={<Radio />} label="Na dachu" />
                        <FormControlLabel value="ground" control={<Radio />} label="Na ziemii" />
                      </RadioGroup>
                      {errors.location && (
                        <Typography variant="body2" color="error">
                          {errors.location.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#fccc44', color: '#000', marginTop: '16px' }}
              >
                Oblicz
              </Button>
            </form>
          </>
        )}
      </Box>
    </Box>
  );
}

export default SectionTwo;
