import React from 'react';
import { Box, Typography } from '@mui/material';

function SectionOne() {
  return (
    <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', md: 'row' }}>
      <Box flex={1} p={2}>
        <Typography variant="h5" gutterBottom mb={3}>Dlaczego Mycie Paneli Fotowoltaicznych Jest Niezbędne?</Typography>

        <Typography variant="body1" gutterBottom>
          🔋 <strong>Maksymalna Wydajność</strong><br />
          <Typography variant="body2">
            Czyste panele absorbują więcej promieni słonecznych, co oznacza więcej darmowej energii dla Ciebie!
          </Typography>
        </Typography>

        <Typography variant="body1" gutterBottom>
          💰 <strong>Większe Oszczędności</strong><br />
          <Typography variant="body2">
            Większa produkcja energii to niższe rachunki za prąd i szybszy zwrot z inwestycji.
          </Typography>
        </Typography>

        <Typography variant="body1" gutterBottom>
          🛡️ <strong>Ochrona Inwestycji</strong><br />
          <Typography variant="body2">
            Regularne mycie zapobiega osadzaniu się zanieczyszczeń, które mogą prowadzić do uszkodzeń i skrócenia żywotności paneli.
          </Typography>
        </Typography>

        <Typography variant="body1" gutterBottom>
          🌧️ <strong>Deszcz To Za Mało</strong><br />
          <Typography variant="body2">
            Naturalne opady nie usuną trudnych zabrudzeń, takich jak ptasie odchody, osady z drzew czy smog.
          </Typography>
        </Typography>
      </Box>

      <Box flex={1} p={2} alignContent="center">
        <div style={{position: "relative", width: "100%", height: "0px", paddingBottom: "56.25%"}}>
          <iframe style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/66NAn9Qpsro?si=vAsLfLYT9tpKFGcb"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
          </iframe>
        </div>
      </Box>
    </Box>
  );
}

export default SectionOne;
