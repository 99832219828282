import React from 'react';
import { Container, Box, CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import SectionOne from './components/SectionOne';
import SectionTwo from './components/SectionTwo';
import SectionThree from './components/SectionThree';
import logo from './assets/img.png'; // Importuj logo

const theme = createTheme({
  typography: {
    fontFamily: `'Inter', Arial, sans-serif`,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" style={{ backgroundColor: '#ffffff', color: 'black', padding: '20px 0' }}>
        <Box display="flex" justifyContent="center">
          <a href="https://panelenablysk.pl/">
            <img src={logo} alt="Logo" style={{maxWidth: '590px', width: '100%'}}/>
          </a>
        </Box>

        <Box pb={5}>
          <SectionOne/>
        </Box>
        <Box bgcolor="#004225" height={10} /> {/* Separator */}

        <Box py={5}>
          <SectionTwo />
        </Box>
        <Box bgcolor="#004225" height={10} /> {/* Separator */}

        <Box py={5}>
          <SectionThree />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
